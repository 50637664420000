import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .row-cards {
    margin: 50px 0;
  }
`;

export const WrapperTitle = styled.h2`
  margin-bottom: 30px; 
  padding: 0px 2em;
  color: ${({ color }) => color};
  font-size: 2em;
  font-weight: bold;
  text-align: center;
`;

export const WrapperCard = styled.div`
  margin: 0 20px 49px 20px;

  .content {
    display: flex;
    align-items: center;
    background-color: ${({ cardBackgroundColor }) => cardBackgroundColor};
    width: 310px;
    min-height: 160px;
    border-radius: 12px;
    padding: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content-percent {
    color: ${({ mainColor }) => mainColor};
    font-size: 20px;
    font-weight: 600;
  }
`

export const WrapperText = styled.div`
  font-size: 40px;
  text-align: center;
  color: ${({ titleColor }) => titleColor};
  font-weight: 200;
  line-height: 40px;
`

export const Image = styled.img`
  margin: 5px;
  object-fit: cover;
  width: 220px;
  height: 204px;
  border-radius: 10px;
`