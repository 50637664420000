import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Styles
import { Wrapper, WrapperText, QuotationBox, Author } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  return (
    <Wrapper
      backgroundColor="#0000FF"
      id="COMP00008"
    >
      <Row className="justify-content-center g-0 h-100">
        <Col xs={11} sm={11} lg={10}>
          <Row className="justify-content-center g-0 h-100">
            <Col
              xs={12}
              lg={12}
              className="d-flex flex-column justify-content-center wrapper-content"
            >
              <WrapperText titleColor="#FFFFFF" dangerouslySetInnerHTML={{ __html: t("COMP00008.title") }}></WrapperText>
              <WrapperText
                descriptionColor="#FFFFFF"
                alignment="left"
                dangerouslySetInnerHTML={{ __html: t("COMP00008.textTop") }}
              ></WrapperText>
              <QuotationBox>
                <q>{t("COMP00008.quote")}</q>
                <Author>{t("COMP00008.author")}</Author>
              </QuotationBox>
              <WrapperText
                descriptionColor="#FFFFFF"
                alignment="left"
                dangerouslySetInnerHTML={{ __html: t("COMP00008.textBottom") }}
              ></WrapperText>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Wireframe;
