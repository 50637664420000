import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  min-height: 550px;
  background-color: ${({ rightBackgroundColor }) => rightBackgroundColor};

  .col-total {
    min-height: 550px;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.xs} {
    padding: 20px;
  }

  @media ${devices.sm} {
    padding: 40px;
  }

  @media ${devices.md} {
    padding: 40px;
  } 

  @media ${devices.lg} {
    padding: 20px 8em;
  }
`;

export const WrapperTitle = styled.h2`
  padding: 20px;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  color: #0000FF;
`;

export const WrapperText = styled.div`
  font-size: 20px; 
  margin-bottom: 50px;
  text-align: center;
`;

export const CardsCTN = styled.div`
  display: flex;
  gap: 5em;
  margin-top: 20px;
  padding: 1em 5em;

  @media ${devices.xs} {
    flex-direction: column;
  }

  @media ${devices.sm} {
    flex-direction: column;
    align-items: center;
  }

  @media ${devices.md} {
    flex-direction: row;
  }
`

export const WrapperCard = styled.div`
  border-radius: 12px;
  justify-content: center;
  position: relative;
  display: flex;

  .card {
    position: relative;
    display: flex;
    justify-content: space-around;
    margin: 25px;
    border: 2px solid ${({ borderColor }) => borderColor};
    background-color: ${({ cardColor }) => cardColor};

    @media ${devices.xs} {
      width: 280px;
      height: 180px;
      padding: 24px 15px 10px 15px;
    }

    @media ${devices.sm} {
      width: 300px;
      height: 195px;
      padding: 24px 15px 10px 15px;
    }

    @media ${devices.md} {
      width: 300px;
      height: 195px;
      padding: 24px 15px 10px 15px;
    }

    @media ${devices.lg} {
      width: 400px;
      height: 260px;
      padding: 40px 25px 20px 25px;
    }
  }

  .icons {
    background-color: ${({ iconBackgroundColor }) => iconBackgroundColor};
    position: absolute;
    border-radius: 60px;
    width: 80px;
    height: 80px;
    top: -30px;
  }

  .images {
    @media ${devices.xs} {
      width: 40px;
      height: 40px;
    }

    @media ${devices.sm} {
      width: 48px;
      height: 48px;
    }

    @media ${devices.lg} {
      width: 50px;
      height: 50px;
    }
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ fontColor }) => fontColor};
    font-weight: 500;
    text-align: center;

    @media ${devices.xs} {
      font-size: 16px;
      line-height: 20px;
    }

    @media ${devices.sm} {
      font-size: 16px;
      line-height: 20px; 
    }

    @media ${devices.lg} {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .subTitle {
    color: ${({ fontColor }) => fontColor};
    font-weight: 300;
    text-align: center;
    font-weight: 300;
    margin-bottom: 0px;

    @media ${devices.xs} {
      font-size: 14px;
      line-height: 18px;
    }

    @media ${devices.sm} {
      font-size: 14px;
      line-height: 18px;
    }

    @media ${devices.lg} {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;