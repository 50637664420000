import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Styles
import { Wrapper, WrapperTitle, WrapperText } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  return (
    <Wrapper
      backgroundImageURL="/assets/lugar-de-escuta/COMP00007-img.webp" 
      id="COMP00007"
    >
      <Row className="justify-content-center g-0 h-100">
        <Col xs={12} lg={4} className="d-flex justify-content-center align-items-center">
        </Col>
        <Col
          xs={12}
          lg={8}
          xl={7}
          className="d-flex flex-column justify-content-center wrapper-content"
        >
          <WrapperTitle color="#FFFFFF" dangerouslySetInnerHTML={{ __html: t("COMP00007.title") }}></WrapperTitle>
          <WrapperText descriptionColor="#FFFFFF" dangerouslySetInnerHTML={{ __html: t("COMP00007.subtitle") }}></WrapperText>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Wireframe;
