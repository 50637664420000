import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding-bottom: 3em;
  background: rgb(250,196,166);
  background: linear-gradient(180deg, rgba(250,196,166,1) 35%, rgba(255,255,255,1) 100%); 
`;

export const WrapperTitle = styled.h2`
  margin: 60px 0px;
  padding: 0px 20px;
  color: ${({ titleColor }) => titleColor};
  font-size: 36px;
  line-height: 40px;
  font-weight: bold;
  text-align: center;
`;

export const WrapperFounders = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
  width: 100%
`

export const WrapperCard = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 1em 8em;
  
  @media ${devices.xs} {
    flex-direction: column;
  }

  @media ${devices.sm} {
    flex-direction: column;
  }

  @media ${devices.lg} {
    ${({ className }) => className === 'reverse' ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
  }
`;

export const WrapperDesc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 1.2em;

  h3 {
    font-weight: bold;
  }
`;