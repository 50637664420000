import i18n_translations from "../../../src/components/PropagoCheckout/i18n/translations"

const i18n = {
  "pt-BR": {
    translations: {
      Page: {
        title: "Lugar de Escuta: Um projeto para discutir masculinidades e questões sociais importantes",
      },
      ButtonFloating: {
        button: "Doe Agora",
        buttonBottom: "Faça uma doação",
      },
      HEAD00003: {
        title: "<h1>LUGAR DE ESCUTA</h1><h2>O (re)nascimento do homem</h2>",
        subTitle:
          "A DESIGUALDADE RACIAL NO BRASIL É PRESENTE E PERSISTE, MESMO FRENTE A POLÍTICAS PÚBLICAS DE AÇÕES AFIRMATIVAS E DE ENFRENTAMENTO AO RACISMO, SENDO ESTE SISTÊMICO E ESTRUTURAL.",
        button: "Doe agora",
        descriptionLeft:
          "De todo o contingente populacional brasileiro é constituído de pretos e pardos",
        descriptionRight:
          "Dos brasileiros abaixo da linha da pobreza estão enquadrados nesse grupo",
        numberLeft: "56%",
        numberRight: "71%",
        font: "Fonte: IBGE"
      },
      COMP00008: {
        title: "<h2>O Racismo Estrutural </h2>",
        textTop:
          "O Brasil foi a última nação do Ocidente a abolir a escravatura, não criou nenhuma condição para inserção digna da população negra na sociedade. O racismo estrutural foi sendo construído como processo histórico, de tal forma que existe um conjunto de preconceitos direcionados às pessoas negras enraizados nas relações, seja entre indivíduos e instituições, se expressando em ações e atitudes discriminatórias, regulares, mensuráveis e observáveis.",
        quote: "SÓ A PRÓPRIA EDUCAÇÃO É CAPAZ DE DESCONSTRUIR OS MONSTROS QUE CRIOU E CONSTRUIR NOVOS INDIVÍDUOS QUE VALORIZEM E CONVIVAM COM AS DIFERENÇAS",
        author: "(Kabengele Munanga)",
        textBottom: "Apesar do avanço no debate do racismo e da valorização da diversidade cultural e ao contrário do especulado pela sociedade, a pandemia não afetou todos os grupos de maneira equânime. Cada vez mais estudos demonstram como pessoas negras, povos originários e pessoas com deficiências foram e estão sendo impactadas de maneira desproporcional.",
      },
      COMP00016: {
        title: "Valores universais para diminuição da violência e combate ao machismo e racismo",
        textContent:
          `<p>Somos uma organização, um movimento que capacita os homens a quebrar barreiras, quebrar limitações e reescrever as suas histórias para melhor. Aqui impera a narrativa coletiva de crescimento, capacitação e sucesso. Acreditamos que o apoio, o conhecimento, a camaradagem e a escuta empática e ativa impulsionam homens para um futuro de realizações
          <p>Estamos dedicados e comprometidos a desbloquear o verdadeiro potencial dos homens que participam das nossas rodas de conversa. Incentivamos nossos participantes a alcançar seus objetivos na vida e principalmente compartilhar suas experiências valiosas com outros homens. Além disso, atuamos para mitigar o sofrimento psíquico destes homens e a diminuição da violência de gênero e minorias.</p>
          <p>Acreditamos que cada homem tem uma história única para contar e experiências valiosas para compartilhar. Nosso objetivo é fornecer um espaço seguro, de apoio e estimulante onde homens de todas as esferas da vida possam se reunir, aprender uns com os outros e embarcar em uma jornada transformadora rumo ao sucesso.</p>
          <p>Juntos, criamos histórias surpreendentes que inspiram as gerações vindouras.</p>
          <strong>CONEXÃO, CAPACITAÇÃO, CRESCIMENTO E SABEDORIA COMPARTILHADA</strong>`,
        button: "Doe agora",
      },
      COMP00007: {
        title: "A Instituição",
        subtitle:
          `<p>O <strong>Lugar de Escuta</strong> nasceu em 06/11/2019 e foi fundado oficialmente em 25/07/2022 com o intuito de discutir <strong>masculinidades</strong>. Com o tempo, fez-se necessário ampliar as conversas para questões de saúde mental, racismo, machismo, diversidade e outras questões importantes para a sociedade.</p>
          <p>É um projeto amplo de reflexão e cuidado, tendo como pano de fundo as masculinidades. Teve início como uma roda de conversa, em que homens se reúnem para compartilhar suas experiências de vida e conflitos, com o objetivo de gerar possibilidades de mudanças culturais e de comportamento.</p>
          <p>É uma organização sem fins lucrativos, sem vinculação partidária, na qual suas atividades são frutos do esforço coletivo e trabalho de uma rede de apoiadores e equipe de profissionais multidisciplinares.</p>
          <p>Atendemos homens em reuniões quinzenais com grupo reflexivo entre homens. Atuamos em conjunto com o <strong>Instituto Acolher</strong>, que atende adolescentes em medida socioeducativa e homens em situação de vulnerabilidade social.</p>
          <p>Prestamos acolhimento, uma escuta empática e ativa, proporcionando o suporte social e comunitário.</p>`,
        imgAlt: "Lorem Ipsum"
      },
      COMP00004: {
        title: "NOSSOS OBJETIVOS",
        cards: [
          {
            span: "PRODUZIR DIÁLOGOS E DISCUSSÕES",
          },
          {
            span: "ESTIMULAR A FORMAÇÃO E A INFORMAÇÃO ",
          },
          {
            span: "PROMOVER O DEBATE EM INSTITUIÇÕES PÚBLICAS E PRIVADAS",
          },
        ],
        button: "Doe agora",
      },
      COMP00024: {
        title: "<h2>CONHEÇA NOSSOS PROJETOS</h2>",
        cards: [
          { 
            title: "<h3>Workshops Capacitadores</h3><p>Mergulhe em um mundo de desenvolvimento pessoal, auto descoberta e aprimoramento de habilidades por meio de nossos workshops interativos conduzidos por especialistas do setor e líderes inovadores. Ajudamos os homens no crescimento de carreira, relacionamentos pessoais e bem-estar emocional.</p>",
            alt: "Lorem ipsum"
          },
          { 
            title: "<h3>Cura Holística</h3><p>Nossa abordagem é multidisciplinar, nosso atendimento combina terapia, aconselhamento e grupos de apoio, nutre o bem-estar emocional e mental, e traz acolhimento e paz para as vítimas de violência.</p>",
            alt: "Dolor sit"
          },
          { 
            title: "<h3>Programas de Mentoria</h3><p>Conectamos homens com mentores experientes que trilharam o caminho do sucesso. Eles orientam, inspiram e ajudam os assistidos a enfrentarem os desafios da jornada, garantindo que alcancem os objetivos com confiança.</p>",
            alt: "Amet, consequitetur"
          },
          { 
            title: "<h3>Apoio Comunitário</h3><p>Oferecemos fóruns, eventos e encontros que servem de estímulo para os homens conectarem entre si, compartilharem suas aspirações e prosperarem.</p>",
            alt: "Adipiscin elit"
          },
          { 
            title: "<h3>Capacitação de Habilidades</h3><p>Oferecemos treinamento vocacional e educacional, assistência e direcionamento profissional, permitindo que os homens recuperem sua independência.</p>", 
            alt: "sed do"
          },
        ]
      },
      COMP00023: {
        title: "NOSSOS NÚMEROS",
        list: [
          "DE 1500 HOMENS ATENDIDOS",
          "DE 120 HOMENS AJUDADOS",
          "DE 120 ENCONTROS"
        ],
        button: 'DÊ VOZ PARA ESSES HOMENS'
      },
      COMP00022: {
        title: "VEJA COMO VOCÊ PODE FAZER A DIFERENÇA",
        cards: [
          { title: 'R$ 10,00', content: 'Ajuda nos gastos mensais com transporte' },
          { title: 'R$ 50,00', content: 'Ajuda nos gastos mensais de capacitação' },
          { title: 'R$ 250,00', content: 'Apoie uma roda de conversas' },
        ],
        imgAlt: "Lorem Ipsum",
        content: `<p>Juntos, curamos. Juntos, nos capacitamos. Junte-se a nós nesta jornada transformadora de cura e empoderamento.</p><p><strong>Sua doação é um voto de confiança na vida desses homens.</strong></p><p>Com a sua ajuda, podemos reescrever histórias de dor em histórias de resiliência e esperança.</p>`,
        button: "SEJA A MUDANÇA, DOE",
      },
      COMP00021: {
        title: "DOADOR!",
        text: "A sua doação é muito importante para o Lugar de Escuta e faz toda a diferença para nós. Você é peça-chave para que possamos expandir nosso impacto e ajudar um número maior de homens. ",
        cards: [
          {
            title: "É SIMPLES, É FÁCIL, É SEGURO, É CONFIÁVEL.",
            subTitle: "Prestamos contas para todos os nossos doadores, somos transparentes.",
          },
          {
            title: "VALORIZAMOS NOSSOS DOADORES",
            subTitle: "Os doadores mensais (recorrentes) comprometidos com nossa causa e nossa organização, são reconhecidos e valorizados através do <strong>Clube do Bem.</strong>",
          }
        ],
      },
      COMP00019: {
        title: "SOBRE OS FUNDADORES",
        founders: [
          {
            imgAlt: "Lorem Ipsum",
            name: "Faustino Correia de Oliveira Neto",
            bio: `Graduado em Enfermagem (2003) pela Universidade Norte do Paraná, tem mestrado em Saúde Coletiva (2008) pela Universidade do Sagrado Coração. É professor no curso de enfermagem do Senac desde 2016 a 2022, e idealizador e facilitador do Lugar de Escuta, projeto de acolhimento com rodas de conversa sobre masculinidade, realizado desde 2019, em Ribeirão Preto.`
          },
          {
            imgAlt: "Dolor Sit",
            name: "Marcelo Vinicius Domingos Rodrigues dos Santos",
            bio: `Criado na periferia de Ribeirão Preto pela bisavó, Maria Luiza, estudou em escolas públicas até ingressar numa universidade pública. Se formou bacharel em enfermagem, mestre em ciências e atualmente é doutorando em Saúde Pública pela EERP/USP. É pensador e pesquisador das relações entre racismo, saúde pública e serviços de saúde, integra grupos de pesquisas sobre a temática. Tem grande atuação enquanto militante do movimento negro, integra coletivos e instituições a nível local, estadual e nacional. É ainda palestrante sobre as questões raciais e de masculinidade e integrante desde 2018 do grupo <strong>Lugar de Escuta</strong>.`
          }
        ],
        button: "FAÇA PARTE, DOE"
      },
      COMP_ALLYA: {
        title: 'Venha fazer parte do nosso ecossistema do bem!',
        description: `
          <p>O processo de doação é simples, seguro e fácil. O dinheiro doado é revertido para as nossas ações sociais e filantrópicas. Você pode doar uma única vez ou todos os meses, de forma recorrente.</p>
          <p><strong>Doe todos os meses</strong> para a Lugar de Escuta e participe do nosso <strong>Clube do Bem</strong>. Nele você <strong>ganha</strong> uma série de <strong>descontos, vantagens e benefícios</strong> em mais de 30 mil estabelecimentos diversos em todo país, podendo ganhar descontos em:</p>
          <ul>
            <li>Ingressos de cinema;</li>
            <li>Academias;</li>
            <li>Medicamentos;</li>
            <li>Cursos (inclusive graduações);</li>
            <li>Restaurantes</li>
            <li>e muito mais!</li>
          </ul>
          <p>Legal, né? Afinal de contas, doar faz bem!</p>
          `,
        text: `A <strong style="color: #F73D6A;">Allya</strong> tem como objetivo gerar economia e praticidade no dia a dia. Com seus benefícios, é possível manter um estilo de vida mais econômico e inteligente.`,
      },
      CHEC00001: {
        title: "Sua doação irá <strong>transformar a vida</strong> de alguém.",
        legend: "Apoio",
      },
      FAQS00001: {
        title: "<strong>Perguntas Frequentes</strong> sobre a doação:",
        cards: [
          {
            title: "Devo declarar as doações no imposto de renda?",
            subTitle:
              "Em conformidade com as regras da Receita Federal do Brasil, o doador precisa informar todas as doações realizadas em suas declarações financeiras. Lembrando que, segundo o Regulamento do Imposto de Renda – RIR (Decreto nº 9.580, de 22/11/ 2018), não há dedução no Imposto de Renda de Pessoa Física (IRPF) para doações a projetos como os realizados pelo Lugar De Escuta.<br /><br />O valor doado deve ser declarado na ficha DOAÇÕES EFETUADAS sob o código 99 – Outras doações, uma vez que se trata de doação não incentivada (não permite dedução do IR a pagar do doador).",
          },
          {
            title: "Quais são as formas de pagamento para doações no site?",
            subTitle:
              "Você pode fazer sua doação, seja ela pontual ou recorrente, via boleto bancário, cartão de crédito (todas as bandeiras) ou via pix.",
          },
          {
            title: "Por que o Lugar De Escuta emitiu uma cobrança em meu nome?",
            subTitle:
              "Para que os pagamentos sejam mais transparentes e seguros, a Federação Brasileira de Bancos (Febraban) instituiu que todos os boletos sejam registrados em uma plataforma de cobrança desenvolvida pela própria Febraban, que permite aos bancos informar ao cliente toda vez que um novo boleto for emitido em seu nome. Clientes cadastrados no serviço Débito Direto Autorizado (DDA) podem ser informados pelos bancos sobre a cobrança no momento do registro do boleto, pelos canais digitais ou com o envio de torpedos.",
          },
          {
            title: "Se eu não pagar o boleto, meu nome pode ser negativado?",
            subTitle:
              "Não. O pagamento dos boletos enviados pelo Lugar De Escuta é totalmente facultativo.",
          },
          {
            title:
              "Meu boleto veio com um valor que eu não quero doar, o que devo fazer?",
            subTitle:
              "Você pode escolher algum dos valores sugeridos para doação, ou definir uma outra quantia que seja de sua preferência, clicando no botão “outro valor”. Se o valor do boleto emitido não for igual ao que você pretende doar, pode emitir outro.",
          },
          {
            title: "O que eu faço se meu boleto vier sem código de barras?",
            subTitle:
              "Se você recebeu um boleto sem código de barras, isso indica que você está cadastrado no Débito Direto Autorizado (DDA). Por determinação das novas regras da Febraban, clientes cadastrados no serviço DDA não recebem mais boletos com códigos de barras. Para pagar seu boleto basta autorizar o débito junto ao seu banco, pela internet, pelo telefone ou nos caixas eletrônicos.",
          },
          {
            title: "O que é DDA?",
            subTitle:
              "O Débito Direto Autorizado (DDA) é um sistema criado pela Federação Brasileira de Bancos (Febraban) que substitui a emissão de boletos de cobrança impressos pela cobrança eletrônica dos pagamentos. Para ler o comunicado no site da Febraban, acesse: <a href='https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda' target='_blank'>https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda</a>",
          },
          {
            title: "Como posso me cadastrar no DDA?",
            subTitle:
              "Isso é um serviço oferecido pelos bancos. Para se cadastrar no Débito Direto Autorizado (DDA), basta entrar em contato com o seu banco, por telefone, pela internet ou nos caixas eletrônicos. Mesmo após o cadastro, você continua recebendo o boleto impresso; porém, sem o código de barras.",
          },
          {
            title: "Posso me descadastrar do DDA?",
            subTitle: "Para se descadastrar, entre em contato com o seu banco.",
          },
          {
            title:
              "Quero pagar meu boleto, mas a data de vencimento passou. Preciso emitir segunda via?",
            subTitle:
              "Não. Agora os boletos vencidos podem ser pagos em qualquer banco, pela internet, nos caixas eletrônicos ou em casas lotéricas.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visite-nos!`,
        iframeTitle: "Localização do lugar de escuta em Ribeirão Preto"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2023 - Lugar De Escuta ® - Todos os direitos podem ser compartilhados com você.",
        copyrightSpan: "Pergunte-nos como.",
      },
      SectionCookies: {
        title: "Lugar De Escuta se preocupa com você e com a sua privacidade",
        subTitle:
          "O nosso site usa cookies e outras tecnologias para personalizar a sua experiência e compreender como você e os outros visitantes usam o nosso site. Ao navegar pelo site, coletaremos tais informações para utilizá-las com estas finalidades. Caso não aceite, usaremos os cookies apenas necessários para o correto funcionamento do site",
        button: "Aceito",
      },
      Checkout: i18n_translations["pt-BR"].translations,
    },
  },
  "en-US": {
    translations: {
      Page: {
        title: "Lugar de Escuta: A project to discuss masculinities and relevant social issues",
      },
      ButtonFloating: {
        button: "Donate Now",
        buttonBottom: "Make a donation",
      },
      HEAD00003: {
        title: "<h1>LUGAR DE ESCUTA</h1><h2>The (re)birth of man</h2>",
        subTitle:
          "RACIAL INEQUALITY IN BRAZIL IS PRESENT AND IT PERSISTS, EVEN IN THE FACE OF PUBLIC POLICIES OF AFFIRMATIVE ACTIONS AND COMBATING RACISM, WHICH IS SYSTEMIC AND STRUCTURAL.",
        button: "Donate now",
        descriptionLeft:
          "Of the entire Brazilian population is made up of blacks and browns",
        descriptionRight:
          "Of Brazilians below the poverty line are in this group",
        numberLeft: "56%",
        numberRight: "71%",
        font: "Source: IBGE"
      },
      COMP00008: {
        title: "<h2>Structural Racism</h2>",
        textTop:
          "Brazil was the last nation in the West to abolish slavery and did not create any conditions for the dignified insertion of the black population into society. Structural racism has been built as a historical process, in such a way that there is a set of prejudices directed at black people rooted in relationships, whether between individuals or institutions, expressed in discriminatory, regular, measurable, and observable actions and attitudes.",
        quote: "ONLY EDUCATION ITSELF IS CAPABLE OF DECONSTRUCTING THE MONSTERS IT CREATED AND BUILDING NEW INDIVIDUALS WHO VALUE AND LIVE WITH DIFFERENCES",
        author: "(Kabengele Munanga)",
        textBottom: "Despite advances in the debate on racism and the valorization of cultural diversity and contrary to what society speculated, the pandemic did not affect all groups equitably. Increasingly, studies show how black people, indigenous peoples, and people with disabilities have been and are being disproportionately impacted.",
      },
      COMP00016: {
        title: "Universal values for reducing violence and combating machismo and racism",
        textContent:
          `<p>We are an organization, a movement that empowers men to break barriers, break limitations and rewrite their stories for the better. Here, the collective narrative of growth, empowerment, and success prevails. We believe that support, knowledge, camaraderie, and empathetic and active listening drive men towards a future of achievements.
          <p>We are dedicated and committed to unlocking the true potential of men who participate in our conversation circles. We encourage our participants to achieve their goals in life and especially to share their valuable experiences with other men. In addition, we work to mitigate the psychological suffering of these men and reduce gender-based violence and minority oppression.</p>
          <p>We believe that every man has a unique story to tell and valuable experiences to share. Our goal is to provide a safe, supportive, and stimulating space where men from all walks of life can come together, learn from each other, and embark on a transformative journey towards success.</p>
          <p>Together, we create amazing stories that inspire future generations.</p>
          <strong>CONNECTION, EMPOWERMENT, GROWTH AND SHARED WISDOM</strong>`,
        button: "Donate now",
      },
      COMP00007: {
        title: "The Institution",
        subtitle:
          `<p><strong>Lugar de Escuta</strong> was born on 06/11/2019 and was officially founded on 25/07/2022 with the aim of discussing <strong>masculinities</strong>. Over time, it became necessary to expand the conversations to issues of mental health, racism, machismo, diversity, and other important issues for society.</p>
          <p>It is a broad project of reflection and care, with masculinities as its background. It began as a conversation circle, where men gather to share their life experiences and conflicts, with the aim of generating possibilities for cultural and behavioral changes.</p>
          <p>It is a non-profit organization, without political affiliation, in which its activities are the result of collective effort and work by a network of supporters and multidisciplinary professionals.</p>
          <p>We serve men in biweekly meetings with a reflective group among men. We work together with the <strong>Instituto Acolher</strong>, which serves adolescents in socio-educational measures and men in situations of social vulnerability.</p>
          <p>We provide empathetic and active listening, providing social and community support.</p>`,
        imgAlt: "Lorem Ipsum"
      },
      COMP00004: {
        title: "OUR OBJECTIVES",
        cards: [
          {
            span: "PRODUCE DIALOGUES AND DISCUSSIONS",
          },
          {
            span: "STIMULATE TRAINING AND INFORMATION",
          },
          {
            span: "PROMOTE DEBATE IN PUBLIC AND PRIVATE INSTITUTIONS",
          },
        ],
        button: "Donate now",
      },
      COMP00024: {
        title: "<h2>GET TO KNOW OUR PROJECTS</h2>",
        cards: [
          { 
            title: "<h3>Empowering Workshops</h3><p>Immerse yourself in a world of personal development, self-discovery, and skill enhancement through our interactive workshops led by industry experts and innovative leaders. We help men grow in their careers, personal relationships, and emotional well-being.</p>",
            alt: "Lorem ipsum"
          },
          { 
            title: "<h3>Holistic Healing</h3><p>Our approach is multidisciplinary, our care combines therapy, counseling, and support groups, nurtures emotional and mental well-being, and brings comfort and peace to victims of violence.</p>",
            alt: "Dolor sit"
          },
          { 
            title: "<h3>Mentorship Programs</h3><p>We connect men with experienced mentors who have walked the path to success. They guide, inspire, and help those assisted to face the challenges of the journey, ensuring that they achieve their goals with confidence.</p>",
            alt: "Amet, consequitetur"
          },
          { 
            title: "<h3>Community Support</h3><p>We offer forums, events, and meetings that serve as a stimulus for men to connect with each other, share their aspirations, and thrive.</p>",
            alt: "Adipiscin elit"
          },
          { 
            title: "<h3>Skill Training</h3><p>We offer vocational and educational training, professional assistance and guidance, allowing men to regain their independence.</p>", 
            alt: "sed do"
          },
        ]
      },
      COMP00023: {
        title: "OUR NUMBERS",
        list: [
          "OF 1500 MEN ASSISTED",
          "OF 120 MEN HELPED",
          "OF 120 MEETINGS"
        ],
        button: 'GIVE VOICE TO THESE MEN'
      },
      COMP00022: {
        title: "SEE HOW YOU CAN MAKE A DIFFERENCE",
        cards: [
          { title: 'R$ 10.00', content: 'Helps with monthly transportation expenses' },
          { title: 'R$ 50.00', content: 'Helps with monthly training expenses' },
          { title: 'R$ 250.00', content: 'Support a round table discussion' },
        ],
        imgAlt: "Lorem Ipsum",
        content: `<p>Together, we heal. Together, we empower. Join us on this transformative journey of healing and empowerment.</p><p><strong>Your donation is a vote of confidence in the lives of these men.</strong></p><p>With your help, we can rewrite stories of pain into stories of resilience and hope.</p>`,
        button: "BE THE CHANGE, DONATE",
      },
      COMP00021: {
        title: "DONOR!",
        text: "Your donation is very important to Lugar de Escuta and makes all the difference to us. You are a key piece for us to expand our impact and help more men.",
        cards: [
          {
            title: "IT'S SIMPLE, IT'S EASY, IT'S SAFE, IT'S RELIABLE.",
            subTitle: "We are accountable to all of our donors, we are transparent.",
          },
          {
            title: "WE VALUE OUR DONORS",
            subTitle: "Monthly (recurring) donors committed to our cause and our organization are recognized and valued through the <strong>Clube do Bem.</strong>",
          }
        ],
      },
      COMP00019: {
        title: "ABOUT THE FOUNDERS",
        founders: [
          {
            imgAlt: "Lorem Ipsum",
            name: "Faustino Correia de Oliveira Neto",
            bio: `Graduated in Nursing (2003) from the North Paraná University, has a master's degree in Collective Health (2008) from the Sacred Heart University. He is a professor in the nursing course at Senac from 2016 to 2022, and the ideator and facilitator of Lugar de Escuta, a reception project with conversation wheels about masculinity, held since 2019, in Ribeirão Preto.`
          },
          {
            imgAlt: "Dolor Sit",
            name: "Marcelo Vinicius Domingos Rodrigues dos Santos",
            bio: `Raised in the outskirts of Ribeirão Preto by his great-grandmother, Maria Luiza, he studied in public schools until he entered a public university. He graduated as a bachelor in nursing, master in sciences and is currently a PhD student in Public Health at EERP/USP. He is a thinker and researcher of the relationships between racism, public health and health services, integrates research groups on the subject. He has great performance as a militant of the black movement, integrates collectives and institutions at the local, state and national levels. He is also a speaker on racial and masculinity issues and has been a member of the <strong>Lugar de Escuta</strong> group since 2018.`
          }
        ],
        button: "BE PART OF IT, DONATE"
      },
      COMP_ALLYA: {
        title: 'Come and join our ecosystem of good!',
        description: `
          <p>The donation process is simple, safe and easy. The money donated goes towards our social and philanthropic actions. You can donate once or every month on a recurring basis.</p>
          <p><strong>Donate every month</strong> to Lugar de Escuta and take part in our <strong>Clube do Bem (Good Club)</strong>. Here <strong>you get a series of discounts, advantages and benefits</strong> at over 30,000 different establishments throughout Brazil in:</p>
          <ul>
            <li>Cinema tickets;</li>
            <li>Gymns;</li>
            <li>Medicines;</li>
            <li>Courses (including undergraduate degrees);</li>
            <li>Restaurants</li>
            <li>and a lot more!</li>
          </ul>
          <p>Cool, right? After all, donating is good for you!</p>
          `,
        text: `<a href="https://allya.com.br/" target="_blank" style="color: #F73D6A; font-weight: bold;">Allya</a> aims to generate savings and practicality in everyday life. With its benefits, it is possible to maintain a more economical and intelligent lifestyle.`,
      },
      CHEC00001: {
        title: "Your donation will <strong>change someone's life</strong>",
        legend: "By",
      },
      FAQS00001: {
        title: "<strong>Frequently Asked Questions</strong> about the donation:",
        cards: [
          {
            title: "Does Lugar de Escuta accept donations in kind",
            subTitle:
              "Unfortunately, we cannot accept donations in kind.",
          },
          {
            title: "How can i be sure my online donation has been accepted?",
            subTitle:
              "If you entered a valid email address, you will receive a message confirming your donation. If you don't receive an email within about 15 minutes, please contact us.",
          },
          {
            title: "What are the payment methods for donations on the website?",
            subTitle: "You can make your donation, whether punctual or recurring by credit card."
          },
          {
            title:"Why is monthly giving the best option?",
            subTitle:
              "Monthly giving is the best option for both Lugar de Escuta and our supporters. It allows us to have a dependable base of support and provides predictable funding. For our supporters it is the easiest way to donate, since your credit card deducts your giving automatically.",
          },
          {
            title: "Is the donation page secure?",
            subTitle: "We are proud to meet strict data security standards in order to process your donation online.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visit us!`,
        iframeTitle: "Localization of Lugar de Escuta in Ribeirão Preto - SP (Brazil)"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2023 - Lugar de Escuta ® - All rights can be shared with you.",
        copyrightSpan: "Ask us how.",
      },
      SectionCookies: {
        title: "Lugar de Escuta worries about your privacy.",
        subTitle:
          "This website uses cookies and other technologies to improve your experience and to analyze the traffic in our website. Once you are browsing we will colect such information for this analysis. If you don't accept it, we will use only cookies that are needed to run the website.",
        button: "I Accept",
      },
      Checkout: i18n_translations.en.translations,
    }
  },
  es: {
    translations: {
      Page: {
        title: "Lugar de Escuta: un proyecto para debatir sobre masculinidades y cuestiones sociales relevantes",
      },
      ButtonFloating: {
        button: "Dona ahora",
        buttonBottom: "Haz una donácion",
      },
      
      HEAD00003: {
        title: "<h1>LUGAR DE ESCUTA</h1><h2>El (re)nacimiento del hombre</h2>",
        subTitle:
          "La desigualdad racial en Brasil es presente y persiste, incluso frente a políticas públicas de acciones afirmativas y de enfrentamiento al racismo, siendo este sistémico y estructural.",
        button: "Dona ahora",
        descriptionLeft:
          "De todo el contingente poblacional brasileño está constituido por negros y pardos",
        descriptionRight:
          "De los brasileños por debajo de la línea de pobreza están encuadrados en este grupo",
        numberLeft: "56%",
        numberRight: "71%",
        font: "Fuente: IBGE"
      },
      COMP00008: {
        title: "<h2>El Racismo Estructural </h2>",
        textTop:
          "Brasil fue la última nación del Occidente en abolir la esclavitud, no creó ninguna condición para la inserción digna de la población negra en la sociedad. El racismo estructural fue siendo construido como proceso histórico, de tal forma que hay un conjunto de prejuicios direccionados a las personas negras enraizados en las relaciones, sea entre individuos e instituciones, expresándose en acciones y actitudes discriminatorias, regulares, mensurables y observables.",
        quote: "SÓLO LA PROPIA EDUCACIÓN ES CAPAZ DE DESCONSTRUIR LOS MONSTRUOS QUE CREÓ Y CONSTRUIR NUEVOS INDIVIDUOS QUE VALOREN Y CONVIVAN CON LAS DIFERENCIAS",
        author: "(Kabengele Munanga)",
        textBottom: "A pesar del avance en el debate del racismo y de la valorización de la diversidad cultural y al contrario de lo especulado por la sociedad, la pandemia no afectó a todos los grupos de manera equitativa. Cada vez más estudios demuestran cómo personas negras, pueblos originarios y personas con discapacidades fueron y están siendo impactadas de manera desproporcional.",
      },
      COMP00016: {
        title: "Valores universales para disminución de la violencia y combate al machismo y racismo",
        textContent:
          `<p>Somos una organización, un movimiento que capacita a los hombres a romper barreras, superar limitaciones y reescribir sus historias para mejor. Aquí impera la narrativa colectiva de crecimiento, capacitación y éxito. Creemos que el apoyo, el conocimiento, la camaradería y la escucha empática y activa impulsan a los hombres hacia un futuro de logros.
          <p>Estamos dedicados y comprometidos a desbloquear el verdadero potencial de los hombres que participan en nuestras charlas. Alentamos a nuestros participantes a alcanzar sus objetivos en la vida y, sobre todo, a compartir sus valiosas experiencias con otros hombres. Además, trabajamos para mitigar el sufrimiento psicológico de estos hombres y reducir la violencia de género y las minorías.</p>
          <p>Cada hombre tiene una historia única que contar y experiencias valiosas que compartir. Nuestro objetivo es proporcionar un espacio seguro, de apoyo y estimulante donde los hombres de todas las esferas de la vida puedan reunirse, aprender unos de otros y embarcarse en un viaje transformador hacia el éxito.</p>
          <p>Juntos, creamos historias sorprendentes que inspiran a las generaciones futuras.</p>
          <strong>CONECTIVIDAD, CAPACITACIÓN, CRECIMIENTO Y SABIDURÍA COMPARTIDA</strong>`,
        button: "Dona ahora",
      },
      COMP00007: {
        title: "La Institución",
        subtitle:
          `<p><strong>Lugar de Escuta</strong> nació el 06/11/2019 y fue fundado oficialmente el 25/07/2022 con el objetivo de discutir <strong>masculinidades</strong>. Con el tiempo, se hizo necesario ampliar las conversaciones para abordar temas de salud mental, racismo, machismo, diversidad y otros temas importantes para la sociedad.</p>
          <p>Es un proyecto amplio de reflexión y cuidado, que tiene como telón de fondo las masculinidades. Comenzó como una rueda de conversación en la que los hombres se reúnen para compartir sus experiencias de vida y conflictos, con el objetivo de generar posibilidades de cambio cultural y de comportamiento.</p>
          <p>Es una organización sin fines de lucro, sin afiliación partidaria, cuyas actividades son fruto del esfuerzo colectivo y del trabajo de una red de apoyo y equipo de profesionales multidisciplinarios.</p>
          <p>Atendemos a hombres en reuniones quincenales con grupos reflexivos entre hombres. Trabajamos en conjunto con el <strong>Instituto Acolher</strong>, que atiende a adolescentes en medidas socioeducativas y hombres en situación de vulnerabilidad social.</p>
          <p>Brindamos acogida, una escucha empática y activa, proporcionando apoyo social y comunitario.</p>`,
        imgAlt: "Lorem Ipsum"
      },
      COMP00004: {
        title: "NUESTROS OBJETIVOS",
        cards: [
          {
            span: "PRODUCIR DIÁLOGOS Y DISCUSIONES",
          },
          {
            span: "ESTIMULAR LA FORMACIÓN Y LA INFORMACIÓN ",
          },
          {
            span: "PROMOVER EL DEBATE EN INSTITUCIONES PÚBLICAS Y PRIVADAS",
          },
        ],
        button: "Dona ahora",
      },
      COMP00024: {
        title: "<h2>CONOZCA NUESTROS PROYECTOS</h2>",
        cards: [
          { 
            title: "<h3>Talleres Capacitadores</h3><p>Sumérgete en un mundo de desarrollo personal, autodescubrimiento y mejora de habilidades a través de nuestros talleres interactivos dirigidos por expertos del sector y líderes innovadores. Ayudamos a los hombres en el crecimiento profesional, las relaciones personales y el bienestar emocional.</p>",
            alt: "Lorem ipsum"
          },
          { 
            title: "<h3>Cura Holística</h3><p>Nuestro enfoque es multidisciplinario, nuestra atención combina terapia, asesoramiento y grupos de apoyo, nutre el bienestar emocional y mental, y brinda acogida y paz a las víctimas de violencia.</p>",
            alt: "Dolor sit"
          },
          { 
            title: "<h3>Programas de Mentoria</h3><p>Conectamos a los hombres con mentores experimentados que han recorrido el camino del éxito. Los orientan, inspiran y ayudan a enfrentar los desafíos del camino, asegurando que alcancen sus objetivos con confianza.</p>",
            alt: "Amet, consequitetur"
          },
          { 
            title: "<h3>Apoio Comunitário</h3><p>Ofrecemos foros, eventos y encuentros que sirven como estímulo para que los hombres se conecten entre sí, compartan sus aspiraciones y prosperen.</p>",
            alt: "Adipiscin elit"
          },
          { 
            title: "<h3>Capacitación de Habilidades</h3><p>Ofrecemos capacitación vocacional y educativa, asistencia y orientación profesional, permitiendo que los hombres recuperen su independencia.</p>", 
            alt: "sed do"
          },
        ]
      },
      COMP00023: {
        title: "NUESTROS NÚMEROS",
        list: [
          "DE 1500 HOMBRES ATENDIDOS",
          "DE 120 HOMBRES AYUDADOS",
          "DE 120 ENCUENTROS"
        ],
        button: 'DA VOZ A ESTOS HOMBRES'
      },
      COMP00022: {
        title: "VEA CÓMO PUEDE HACER LA DIFERENCIA",
        cards: [
          { title: 'R$ 10,00', content: 'Ayuda en los gastos mensuales de transporte' },
          { title: 'R$ 50,00', content: 'Ayuda en los gastos mensuales de capacitación' },
          { title: 'R$ 250,00', content: 'Apoye una mesa redonda' },
        ],
        imgAlt: "Lorem Ipsum",
        content: `<p>Juntos, curamos. Juntos, nos capacitamos. Únase a nosotros en este viaje transformador de curación y empoderamiento.</p><p><strong>Su donación es un voto de confianza en la vida de estos hombres.</strong></p><p>Con su ayuda, podemos reescribir historias de dolor en historias de resiliencia y esperanza.</p>`,
        button: "SEA EL CAMBIO, DONE",
      },
      COMP00021: {
        title: "DONANTE!",
        text: "Su donación es muy importante para Lugar de Escuta y hace toda la diferencia para nosotros. Usted es una pieza clave para que podamos expandir nuestro impacto y ayudar a un mayor número de hombres. ",
        cards: [
          {
            title: "ES SIMPLE, ES FÁCIL, ES SEGURO, ES CONFIABLE.",
            subTitle: "Rendimos cuentas a todos nuestros donantes, somos transparentes.",
          },
          {
            title: "VALORAMOS A NUESTROS DONANTES",
            subTitle: "Los donantes mensuales (recurrentes) comprometidos con nuestra causa y nuestra organización son reconocidos y valorados a través del <strong>Club del Bienestar.</strong>",
          }
        ],
      },
      COMP00019: {
        title: "SOBRE LOS FUNDADORES",
        founders: [
          {
            imgAlt: "Lorem Ipsum",
            name: "Faustino Correia de Oliveira Neto",
            bio: `Graduado en Enfermería (2003) por la Universidad Norte de Paraná, tiene una maestría en Salud Colectiva (2008) por la Universidad del Sagrado Corazón. Es profesor en el curso de enfermería del Senac desde 2016 a 2022, e ideador y facilitador del Lugar de Escucha, proyecto de acogida con ruedas de conversación sobre masculinidad, realizado desde 2019, en Ribeirão Preto.`
          },
          {
            imgAlt: "Dolor Sit",
            name: "Marcelo Vinicius Domingos Rodrigues dos Santos",
            bio: `Creado en la periferia de Ribeirão Preto por la bisabuela, Maria Luiza, estudió en escuelas públicas hasta ingresar a una universidad pública. Se graduó como bachiller en enfermería, maestro en ciencias y actualmente es doctorando en Salud Pública por la EERP/USP. Es pensador e investigador de las relaciones entre racismo, salud pública y servicios de salud, integra grupos de investigación sobre la temática. Tiene gran actuación mientras militante del movimiento negro, integra colectivos e instituciones a nivel local, estatal y nacional. Es aún orador sobre las cuestiones raciales y de masculinidad e integrante desde 2018 del grupo <strong>Lugar de Escucha</strong>.`
          }
        ],
        button: "SEA PARTE, DONE"
      },
      COMP_ALLYA: {
        title: '¡Venga y forme parte de nuestro ecosistema del bien!',
        description: `
          <p>El proceso de donación es sencillo, seguro y fácil. El dinero donado se destina a nuestras actividades sociales y filantrópicas. Puedes donar una vez o cada mes de forma periódica.</p>
          <p><strong>Colabore todos los meses</strong> con la Lugar de Escuta y participe en nuestro <strong>Clube do Bem (Club del Bien)</strong>, donde <strong>obtendrá una serie de descuentos, ventajas y beneficios</strong> en más de 30.000 establecimientos diferentes de todo el Brasil en:</p>
          <ul>
            <li>Entradas de cine;</li>
            <li>Gimnasios;</li>
            <li>Medicamentos;</li>
            <li>Cursos (incluidas las titulaciones);</li>
            <li>Restaurantes</li>
            <li>¡y mucho más!</li>
          </ul>
          <p>Genial, ¿verdad? Al fin y al cabo, ¡donar es bueno para usted!</p>
          `,
        text: `El objetivo de <a href="https://allya.com.br/" target="_blank" style="color: #F73D6A; font-weight: bold;">Allya</a> es generar ahorro y practicidad en la vida cotidiana. Con sus ventajas, es posible un estilo de vida más económico e inteligente`,
      },
      CHEC00001: {
        title: "Su donación <strong>transformará la vida</strong> de alguien.",
        legend: "Apoyo",
      },
      FAQS00001: {
        title: "<strong>Preguntas frecuentes</strong> sobre la donación:",
        cards: [
          {
            title: "¿Lugar de Escuta acepta donaciones en especie (ropa, alimentos, agua, etc.)?",
            subTitle:
              "Desafortunadamente, no podemos aceptar donaciones en especie.",
          },
          {
            title: "¿Cómo puedo estar seguro de que mi donación en línea ha sido aceptada?",
            subTitle:
              "Si ingresaste una dirección de correo electrónico válida, recibirás un mensaje de confirmación de tu donación. Si no recibes un correo electrónico en 15 minutos, contáctenos.",
          },
          {
            title: "¿Cuáles son los métodos de pago para las donaciones en el sitio web?",
            subTitle:
              "Puedes hacer tu donación, ya sea puntual o recurrente con tarjeta de crédito.",
          },
          {
            title: "¿Por qué la donación recurrente es la mejor opción?",
            subTitle:
              "La donación mensual es la mejor opción tanto para Lugar de Escuta como para nuestros seguidores. Ella nos permite tener una base de apoyo confiable y proporciona financiamiento predecible. Para nuestros seguidores, es la forma más fácil de donar, ya que la tarjeta de crédito descuenta la donación automáticamente..",
          },
          {
            title:
              "¿Es segura la página de donaciones?",
            subTitle:
              "Estamos orgullosos de cumplir con estrictos estándares de seguridad de datos para procesar tu donación en línea.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Haga una visita a nosotros!`,
        iframeTitle: "Ubicación de Lugar de Escuta en Ribeirão Preto - SP (Brasil)"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2023 - Lugar de Escuta® - Todos los derechos pueden ser compartidos con usted.",
        copyrightSpan: "Pregúntanos cómo.",
      },
      SectionCookies: {
        title: "Lugar de Escuta preocupase contigo e tu privacidad.",
        subTitle:
          "Este sitio web utiliza cookies y otras tecnologias para mejorar y optimizar la experiencia del usuario, bien como para analizar la atividade de los visitantes y su frequência de utilización. Si no aceptas, utilizaremos únicamente las cookies con finalidad técnica, para que la página web funcione.",
        button: "Acepto",
      },
      Checkout: i18n_translations.es.translations,
    }
  },
};

export default i18n;
