import styled from 'styled-components';
import devices from '../../../utils/helperGrids';

export const Wrapper = styled.div`
    display: grid; 
    place-items: center;
    background-color: ${({ backgroundColor }) => backgroundColor};

    @media ${devices.xs} {
        padding: 2em .2em 0;
    }

    @media ${devices.sm} {
        padding: 2em .2em 0;
    }

    @media ${devices.md} {
        padding: 2em 8em 0;
    }

    iframe {
        border-radius: 10px;
    }
`

export const WrapperTitle = styled.h2`
    color: ${({ titleColor }) => titleColor};
    margin-bottom: .4em;
    text-align: center;
    font-size: 2em;
    font-weight: bold;
` 