import styled from 'styled-components'
import devices from '../../../utils/helperGrids'

export const Wrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-image: url(${({ backgroundImageURL }) => backgroundImageURL});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  padding: 50px 0;

  .wrapper-content {
    @media ${devices.sm} {
      padding: 0 2em;
    }
    @media ${devices.xs} {
      padding: 0 2em;
    }
    @media ${devices.lg} {
      padding-left: 70px;
    }
  }
  
  .button {
    @media ${devices.xs} {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }

    @media ${devices.sm} {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }

    @media ${devices.lg} {
      justify-content: flex-start;
      margin-top: 0;
    }
  }
`

export const WrapperTitle = styled.h2`
  color: ${({ color }) => color};
  font-size: 40px;
  font-weight: bold;
  line-height: 44px;
  text-align: center;
  
  @media ${devices.xs} {
    margin-top: 30px;
  }

  @media ${devices.sm} {
    margin-top: 30px;
  }

  @media ${devices.lg} {
    margin-top: 0px;
    margin-bottom: 27px;
  }
`;

export const WrapperText = styled.div`
  ${({ descriptionColor }) => descriptionColor ? `
    font-weight: 500;
    color: ${descriptionColor};
    
    @media ${devices.xs} {
      font-size: 16px;
      line-height: 20px;
      margin-top: 30px;
    }

    @media ${devices.sm} {
      font-size: 16px;
      line-height: 20px;
      margin-top: 30px;
    }

    @media ${devices.lg} {
      font-size: 20px;
      line-height: 24px;
      margin-top: 0px;
      margin-bottom: 27px;
    }
  ` : ''}

  strong {
    font-weight: 600;
  }
`