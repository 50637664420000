import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  min-height: 780px;
  background-color: ${({ backgroundColor }) => backgroundColor};

  .col-left {
    justify-content: space-evenly;
    @media ${devices.xs} {
      padding: 40px;
    }

    @media ${devices.sm} {
      padding: 40px;
    }

    @media ${devices.lg} {
      padding: 60px 0;
    }
  }

  .button {
    margin-top: 3em;
  }

  .col-right {
    background-image: url(${({ leftBackgroundImageURL }) => leftBackgroundImageURL});
    background-repeat: no-repeat;
    background-size: cover;
  }

  .imageIcon {
    text-align: center;
    ${({ imageCenter }) => imageCenter ? 'margin: 0 auto;' : ''}
  }
`;

export const Image = styled.img`
  max-width: 305px;
  heigth: 90px;
  margin-bottom: 30px;

  @media ${devices.xs} {
    max-width: 226px;
    heigth: 66px;
  }
`;

export const WrapperTitle = styled.h2`
  margin: 8px 0 8px 0;
  color: ${({ color }) => color};
  font-weight: 900;

  @media ${devices.xs} {
    text-align: center;
    font-size: 28px;
    line-height: 32px;
  }

  @media ${devices.sm} {
    text-align: center;
    font-size: 28px;
    line-height: 32px;
  }

  @media ${devices.lg} {
    text-align: left;
    font-size: 30px;
    line-height: 34px;
  }

  @media ${devices.xl} {
    font-size: 32px;
    line-height: 36px;
  }
`

export const WrapperText = styled.div`
  color: ${({ color }) => color};
  font-size: 20px;
  line-height: 24px;
  font-weight: 300;
  margin-top: 1.5em; 

  @media ${devices.xs} {
    font-size: 16px;
    line-height: 20px;
  }
`;
