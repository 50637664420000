import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;

  .left {
    background-image: url(${({ leftBackgroundImageURL }) => leftBackgroundImageURL});
    background-repeat: no-repeat;
    background-size: cover;
  }

  .right {
    @media ${devices.xs} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media ${devices.sm} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media ${devices.md} {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 40px;
    }
  }

  .button {
    margin-top: 40px;
  }
`;

export const WrapperTitle = styled.h2`
  margin-bottom: 40px;
  color: ${({ titleColor}) => titleColor};
  font-weight: 700;

  @media ${devices.xs} {
    font-size: 28px;
    line-height: 32px;
  }

  @media ${devices.sm} {
    font-size: 28px;
    line-height: 32px;
  }

  @media ${devices.md} {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const ListItem = styled.span`
  @media ${devices.xs} {
    font-size: 16px;
    line-height: 20px; 
  }

  @media ${devices.sm} {
    font-size: 20px;
    line-height: 24px;
  }

  @media ${devices.lg} {
    font-size: 24px;
    line-height: 28px
  }
`