import { Image, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Utils
import { scrollTo } from "../../../utils/helperScrolls"

// Components
import Button from "../../Button";
import DropdownLocale from "../../DropdownLocale";

// Assets
import { ReactComponent as ArrowDown } from "../../../assets/svgs/arrowDown.svg";

// Styles
import { Wrapper, ArrowWrapper, WrapperCard } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  return (
    <Wrapper 
      backgroundImageURL="/assets/lugar-de-escuta/HEAD00003-img.webp" 
      titleColor="#FFFFFF" 
      subtitleColor="#FFFFFF"
    >
      <div className="wrapper-insider">
        <div className="wrapper-button-i18n">
          <div className="d-flex justify-content-center">
            <Col xs={11} md={10} lg={10} xl={8}>
              <DropdownLocale />
            </Col>
          </div>
        </div>
        <div className="d-flex justify-content-center selo">
          <Col md={3} className="imageSelo">
            <Image 
              src="/assets/SELO.webp" 
              width={123} 
              height={139} 
              alt='' 
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center logo">
          <Col md={3} className="d-flex justify-content-center">
            <Image 
              src="/assets/lugar-de-escuta/HEAD00003-logo.svg" 
              width={270}
              height={200} 
              alt=''
              fluid 
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center title">
          <Col xs={10} md={8} lg={7} xl={6} className="text-center" dangerouslySetInnerHTML={{ __html: t("HEAD00003.title") }}></Col>
        </div>
        <div className="d-flex justify-content-center sub-title">
          <Col xs={10} md={8} lg={7} xl={6} className="text-center">
            {t("HEAD00003.subTitle")}
          </Col>
        </div>
        <div className="d-flex justify-content-center button">
          <Button
            primaryColor="#FF6819"
            secondaryColor="#FFFFFF"
          >
            {t("HEAD00003.button")}
          </Button>
        </div>
      </div>
      <div className="d-flex flex-xs-column cards">
        <WrapperCard 
          subtitleColor="#FFFFFF" 
          borderCardColor="rgba(255,255,255,0.4)" 
          cardColor="rgb(250, 196, 166,.7)" 
          textNumberColor="#000000" 
          numberColor="#FFFFFF"
        >
          <div className="number">
            <Col xs={12} md={7} lg={10} xl={10} className="textNumber">
              {t("HEAD00003.numberLeft")}
            </Col>
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <Col xs={10} md={10} lg={10} xl={10} className="subTitleLeft">
              {t("HEAD00003.descriptionLeft")}
            </Col>
            <div>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="d-none d-sm-block font"
              >
                {t("HEAD00003.font")}
              </Col>
            </div>
          </div>
        </WrapperCard>
        <WrapperCard
          subtitleColor="#FFFFFF" 
          borderCardColor="rgba(255,255,255,0.4)" 
          cardColor="rgb(250, 196, 166,.7)" 
          textNumberColor="#000000" 
          numberColor="#FFFFFF"
        >
          <div className="number">
            <Col xs={12} md={7} lg={10} xl={10} className="textNumber">
              {t("HEAD00003.numberRight")}
            </Col>
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <Col xs={10} md={10} lg={10} xl={10} className="subTitleRight">
              {t("HEAD00003.descriptionRight")}
            </Col>
          </div>
        </WrapperCard>
        <div className="d-block d-sm-none font">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {t("HEAD00003.font")}
          </Col>
        </div>
      </div>
      <div>
        <ArrowWrapper
          onClick={() => {
            scrollTo("COMP00008");
          }}
          arrowColor="#FFFFFF"
        >
          <ArrowDown />
        </ArrowWrapper>
      </div>
    </Wrapper>
  );
};

export default Wireframe;
