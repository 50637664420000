import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
`;

export const WrapperText = styled.div`
  ${({ titleColor }) =>
    titleColor
      ? `
    font-size: 35px;
    line-height: 40px;
    font-weight: bold;
    color: ${titleColor};
    text-align: center;
    
    @media ${devices.xs} {
      margin-top: 30px;
    }

    @media ${devices.sm} {
      margin-top: 30px;
    }

    @media ${devices.lg} {
      margin-top: 50px;
    }
  `
      : ""}

  ${({ descriptionColor, alignment }) =>
    descriptionColor
      ? `
    margin: 1em 0; 
    font-size: 18px;
    line-height: 22px;
    color: ${descriptionColor};
    text-align: ${alignment};
  `
      : ""}
`;

export const QuotationBox = styled.div`
  padding: 2em 2em 1em;
  background-color: #FFFFFF;
  color: #000000;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  line-height: 24px; 

  @media ${devices.xs} {
    width: 100%;
    margin: 2em 0;
  }

  @media ${devices.sm} {
    width: 75%;
    margin: 2em auto;
  }
`;

export const Author = styled.small`
  display: block;
  margin-top: 10px;
  text-align: right;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;