import _ from "lodash";
import { Row, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Styles
import { Wrapper, CardsCTN, WrapperTitle, WrapperCard, WrapperText } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const cards = t("COMP00021.cards", { returnObjects: true });
  return (
    <Wrapper 
      rightBackgroundColor="#FAC4A6"
      id="COMP00021"
    >
      <Row className="d-flex justify-content-center align-items-center flex-column g-0 h-100 col-total">
        <Col
          xs={12}
          sm={10}
          className="d-flex justify-content-center align-items-center flex-column"
        >
          <WrapperTitle>{t("COMP00021.title")}</WrapperTitle>
          <WrapperText dangerouslySetInnerHTML={{ __html: t("COMP00021.text") }}></WrapperText>
          <CardsCTN>
          {
          _.map(cards, (item, index) => (
            <WrapperCard 
              borderColor="#0000FF"
              cardColor="#FFFFFF"
              iconBackgroundColor="#0000FF"
              fontColor="#0000FF"
              key={index}
            >
              <div className="d-flex card">
                <div className="title">{_.get(item, "title")}</div>
                <WrapperText className="subTitle" dangerouslySetInnerHTML={{ __html: _.get(item, "subTitle") }}></WrapperText>
              </div>
              <div className="d-flex justify-content-center align-items-center icons">
                <Image
                  className="images"
                  src={`/assets/lugar-de-escuta/COMP00021-icon${index+1}.svg`}
                  width={64}
                  height={64}
                  alt={''}
                />
              </div>
            </WrapperCard>
          ))
          }
          </CardsCTN>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Wireframe;
