import _ from "lodash";
import { Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components 
import Button from "../../Button";

// Styles
import { Wrapper, WrapperTitle, WrapperCard, WrapperDesc, WrapperFounders } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const FOUNDERS_LIST = t("COMP00019.founders", { returnObjects: true })
  return (
    <Wrapper 
      backgroundImageURL="/assets/pet-sem-fronteiras/COMP00019-img.jpg"
      id="COMP00019"
    >
      <div className="block-top d-flex flex-column justify-content-center align-items-center">
        <Col
          xs={9}
          sm={10}
          md={10}
          lg={12}
          className="d-flex justify-content-center"
        >
          <WrapperTitle titleColor="#0000FF" dangerouslySetInnerHTML={{ __html: t("COMP00019.title") }}></WrapperTitle>
        </Col>
      </div>
      <div className="d-flex flex-column">
        <WrapperFounders>
          {
            _.map(FOUNDERS_LIST, (item, index) => {
              return (
                <WrapperCard className={ index % 2 !== 0 && 'reverse' }>          
                  <Image 
                    src={`/assets/lugar-de-escuta/COMP00019-img${index+1}.webp`}
                    width={300} 
                    height={297} 
                    alt={_.get(item, 'imgAlt')} 
                  />
                  <WrapperDesc>
                    <h3 className="text-center">{_.get(item, 'name')}</h3>
                    <div dangerouslySetInnerHTML={{ __html: _.get(item, 'bio') }}></div>
                  </WrapperDesc>
                </WrapperCard>
              );
            })
          }
        </WrapperFounders>
      </div>
      <div className="d-flex button">
        <Button
          primaryColor="#FF6819"
          secondaryColor="#FFFFFF"
          icon={false}
          buttonWidthVariable={true}
        >
          {t("COMP00019.button")}
        </Button>
      </div>
    </Wrapper>
  );
};

export default Wireframe;
