import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  min-height: 800px;
  padding: 70px 0;

  display: flex;
  flex-direction: column;

  svg {
    @media ${devices.md} {
      margin-top: 40px;
    }
    width: 50px;
    height: 25px;
    fill: ${({ primaryColor }) => primaryColor};
    cursor: pointer;
    height: 2em;
    width: 2em;  
  }
  .carousel {
    height: 250px;
    margin-top: 60px;
  }
  .content {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .arrow{
    fill: #0000FF;
  }
`;

export const WrapperImage = styled.div`
  
`

export const Image = styled.img`
  width: 250px;
  height: 250px;
  border-radius: 20px;
  object-fit: cover !important;

  @media ${devices.xs} {
    width: 185px;
  }
`

export const WrapperText = styled.div`
  ${({ titleColor }) => titleColor ? `
    h2 {
      color: ${titleColor};
      font-weight: 700;
      text-align: center;
      font-size: 28px;
    }
  `: ""}

  ${({ descriptionColor }) => descriptionColor ? `
    margin-top: 20px;
    color: ${descriptionColor};
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    
    h3 {
      font-weight: 600;
    }
    
  `: ""}
`;