import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;  
  ${({ backgroundColor }) => backgroundColor ? `
    background-color: ${backgroundColor};
  ` : `
    background-image: url(${({ backgroundImageURL }) => backgroundImageURL});
    background-repeat: no-repeat;
    background-size: cover;
    backgroung-position: center;
  `}
  min-height: 550px;
  padding: 40px 0 40px 0;
  @media ${devices.md} {
    padding: 60px 0 60px 0;
  }

  .button {
    margin-top: 30px;
  }
  
  .image {
    max-height: 300px;
  }

  .cards {
    margin-top: 20px;
    @media ${devices.md} {
      margin-top: 40px;
      div {
        margin-right: 15px;
        margin-left: 15px;
      }
    }
  }
`;

export const WrapperTitle = styled.h2`
  margin-bottom: 1em;
  color: #0000FF;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
`; 

export const WrapperCard = styled.div`
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 12px;
  margin: 20px auto 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .title {
    width: 70%;
    height: 32px;
    background-color: ${({ titleBackgroundColor }) => titleBackgroundColor};
    border-radius: 10px;
    color: ${({ titleColor }) => titleColor};
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .content {
    text-align: center;
    font-size: 14px;
    color: ${({ contentColor }) => contentColor};
  }
`

export const WrapperText = styled.div`
  ${({ textColor }) => textColor ? `
    color: ${textColor};
    font-weight: 400;

    b {
      font-weight: 700;
    }

    @media ${devices.xs} {
      margin-top: 40px;
      font-size: 15px;
      line-height: 24px;
    }

    @media ${devices.sm} {
      margin-top: 40px;
      font-size: 15px;
      line-height: 24px;
    }

    @media ${devices.md} {
      margin-top: 0;
    }
  `: ""}
`;