import _ from "lodash";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Styles
import { Wrapper, WrapperTitle, WrapperCard } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const cards = t("COMP00004.cards", { returnObjects: true });

  return (
    <Wrapper
      backgroundColor="#FAC4A6" 
      id="COMP00004"
    >
      <Row className="justify-content-center g-0 h-100 row-cards">
        <WrapperTitle color="#0000FF">{t("COMP00004.title")}</WrapperTitle>
        {_.map(cards, (item, index) => (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={4}
            className="d-flex justify-content-center"
            key={index}
          >
            <WrapperCard
              mainColor="#0000FF"
              cardBackgroundColor="#FFFFFF"
            >
              <div className="content">
                <div className="content-percent">{_.get(item, "span")}</div>
              </div>
            </WrapperCard>
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
};

export default Wireframe;
