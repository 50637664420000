import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import Button from "../../Button";

// Styles
import { Wrapper, WrapperTitle, WrapperText } from "./styles";

const Wireframe = ({ name }) => {
  const { t } = useTranslation();

  return (
    <Wrapper 
      backgroundColor="#0000FF"
      leftBackgroundImageURL="/assets/lugar-de-escuta/COMP00016-img1.webp"
      imageCenter={false}
      id="COMP00016"
    >
      <Row>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={5}
          className={`d-flex flex-column justify-content-left align-items-lg-baseline col-left offset-lg-1`}
        >
          <WrapperTitle color="#FFFFFF" dangerouslySetInnerHTML={{ __html: t(`${name}.title`) }}></WrapperTitle>
          <Col xs={12}>
            <WrapperText
              color="#FFFFFF"
              dangerouslySetInnerHTML={{ __html: t(`${name}.textContent`) }}
            ></WrapperText>
          </Col>
          <Col
            xs={12}
            className={`d-flex justify-content-center button`}
          >
            <Button
              primaryColor="#FF6819"
              secondaryColor="#FFFFFF"
            >
              {t(`${name}.button`)}
            </Button>
          </Col>
        </Col>
        <Col
          xm={12}
          sm={12}
          md={6}
          className="d-none d-md-flex flex-column justify-content-center align-items-center align-items-md-start col-right"
        ></Col>
      </Row>
    </Wrapper>
  );
};

export default Wireframe;
