import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.header`
  position: relative;
  .wrapper-insider {
    position: relative;
  }

  background-image: url(${({ backgroundImageURL }) => backgroundImageURL});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 1000px;
  align-items: center;

  .logo {
    margin-top: 30px;
    position: relative;
  }

  .selo {
    position: absolute;
    right: 28%;
    top: 7%;

    @media ${devices.xs} {
      right: 7%;
    }

    @media ${devices.sm} {
      right: 15%;
    }

    @media ${devices.md} {
      right: 20%;
      
    }

    @media ${devices.lg} {
      right: 28%;
    }
  }

  .imageSelo {
    @media ${devices.xs} {
      display: none;
    }

    @media ${devices.sm} {
      height: 100px;
    }

    @media ${devices.md} {
      height: 139px;
    }
  }

  .title {
    margin-top: 70px;
    color: ${({ titleColor }) => titleColor};
    font-weight: 900;
    font-size: 35px;
    line-height: 40px;

    @media ${devices.xs} {
      margin-top: 50px;
      font-size: 26px;
      line-height: 30px;
    }

    @media ${devices.sm} {
      margin-top: 50px;
      font-size: 26px;
      line-height: 30px;
    }

    @media ${devices.md} {
      margin-top: 50px;
      font-size: 36px;
      line-height: 40px;
    }
  }

  .sub-title {
    margin-top: 37px;
    font-size: 20px;
    line-height: 24px;
    color: ${({ subtitleColor }) => subtitleColor};
    font-weight: 600;

    @media ${devices.xs} {
      margin-top: 30px;
      font-size: 19px;
      line-height: 23px;
    }

    @media ${devices.sm} {
      margin-top: 34px;
      font-size: 18px;
      line-height: 22px;
    }

    @media ${devices.md} {
      margin-top: 37px;
      font-size: 20px;
      line-height: 24px;
    }
  }
  .button {
    @media ${devices.xs} {
      margin: 30px 0 40px 0;
    } 

    @media ${devices.sm} {
      margin: 40px 0 0px 0;
    } 
  }

  .wrapper-button-i18n {
    position: absolute;
    width: 100%;
    top: 50px;
    z-index: 1;
  }

  .cards {
    display: flex;
    justify-content: space-around;
    height: 100%;
    width: 100%;

    @media ${devices.xs} {
      flex-direction: column;
      align-items: center;
      margin-top: 1em;
      gap: 1em;
    }

    @media ${devices.sm} {
      align-items: center;
      margin-bottom: 80px;
    }

    @media ${devices.md} {
      align-items: end;
      margin-bottom: 65px;
    }
  }

  .font {
    color: ${({ subtitleColor }) => subtitleColor};
    font-style: italic;
  
    @media ${devices.xs} {
      font-size: 10px;
      font-weight: 500;
      padding-left: 30px;
      width: 100%;

    }
`;

export const WrapperCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 3px dotted;
  border-radius: 12px;
  border-color: ${({ borderCardColor }) => borderCardColor};
  background-color: ${({ cardColor }) => cardColor};
  text-align: center;

  @media ${devices.xs} {
    width: 208px;
    height: 140px;
    margin-bottom: 40px;
  }

  @media ${devices.sm} {
    width: 232px;
    height: 156px;
  }

  @media ${devices.md} {
    width: 265px;
    height: 178px;
  }

  .subTitleRight {
    color: ${({ subtitleColor}) => subtitleColor};
    font-weight: bold;

    @media ${devices.xs} {
      font-size: 14px;
      line-height: 20px;
      padding-top: 30px;
    }

    @media ${devices.sm} {
      font-size: 15px;
      line-height: 20px;
      padding-top: 20px;
    }

    @media ${devices.md} {
      font-size: 17px;
      line-height: 24px;
      padding-top: 30px;
    }
  }

  .subTitleLeft {
    color: ${({ subtitleColor }) => subtitleColor};
    font-weight: bold;

    @media ${devices.xs} {
      font-size: 14px;
      line-height: 20px;
      padding-top: 30px;
    }

    @media ${devices.sm} {
      font-size: 15px;
      line-height: 20px;
      padding-top: 90px;
    }

    @media ${devices.md} {
      font-size: 17px;
      line-height: 24px;
      padding-top: 90px;
    }
  }

  .font {
    color: ${({ subtitleColor }) => subtitleColor};
    font-style: italic;

    @media ${devices.xs} {
      font-size: 13px;
    }

    @media ${devices.sm} {
      background-color: ${({ cardColor }) => cardColor} ;
      font-size: 11px;
      padding: 5px;
      margin-top: 40px;
    }

    @media ${devices.md} {
      font-size: 13px;
    }
  }

  .textNumber {
    color: ${({ textNumberColor}) => textNumberColor};
    font-weight: 800;

    @media ${devices.xs} {
      font-size: 22px;
    }

    @media ${devices.sm} {
      font-size: 23px;
    }

    @media ${devices.sm} {
      font-size: 25px;
    }
  }

  .number {
    background-color: ${({ numberColor }) => numberColor};
    border-radius: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;

    @media ${devices.xs} {
      width: 75px;
      height: 75px;
      top: -47px;
      left: 65px;
    }

    @media ${devices.sm} {
      width: 80px;
      height: 80px;
      top: -50px;
      left: 74px;
    }

    @media ${devices.md} {
      width: 94px;
      height: 94px;
      top: -55px;
      left: 80px;
      font-size: 28px;
    }
  }
`;

export const ArrowWrapper = styled.div`
  height: 90px;
  margin-top: -90px;
  display: flex;
  justify-content: center;

  @media ${devices.xs} {
    display: none;
  }

  svg {
    cursor: pointer;

    @media ${devices.xs} {
      width: 43px;
    }

    @media ${devices.sm} {
      width: 48px;
    }

    @media ${devices.md} {
      width: 57px;
    }
  }

  svg #Caminho_247 {
    fill: ${({ arrowColor }) => arrowColor};
  }

  svg #Elipse_283 {
    stroke: ${({ arrowColor }) => arrowColor};
    fill: transparent;
  }
`;
