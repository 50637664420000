import _ from "lodash";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import Button from "../../Button";
import { ReactComponent as AddIcon } from "../../../assets/svgs/addIcon.svg";

// Styles
import { Wrapper, WrapperTitle, ListItem } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const listItems = t('COMP00023.list', { returnObjects: true })

  return (
    <Wrapper
      leftBackgroundImageURL="/assets/lugar-de-escuta/COMP00023-img.webp" 
      id="COMP00023"
    >
      <Row className="g-0 h-100">
        <Col md={6} className="bg-red d-none d-md-block left" />
        <Col xs={12} md={6} className="right">
          <Col xs={10} sm={11} lg={10} xl={10} className="d-flex flex-column justify-content-center">
            <WrapperTitle titleColor="#0000FF" className="text-center mt-4">{t('COMP00023.title')}</WrapperTitle>
            <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
              {
                _.map(listItems, (item) => {
                  let listItemElement = item;
                  return (<li style={{ display: 'flex', alignItems: 'center', gap: '1em' }}><AddIcon width={64} height={64} fill='#0000FF'/><ListItem>{listItemElement}</ListItem></li>)   
                })
              }
            </ul>
            <Col className="d-flex justify-content-center button mb-4">
              <Button
                primaryColor="#FF6819"
                secondaryColor="#FFFFFF"
                icon={false}
                buttonWidthVariable={true}
              >
                {t(`COMP00023.button`)}
              </Button>
            </Col>
          </Col>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Wireframe;
